<template>
  <div class="tariffs-table">
    <base-table
      :columns="tableHeadModify"
      :sort="sort"
      :list="paginList"
      class="tariffs-table__table"
      @sort-change="onSortChange"
    >
      <tariffs-table-port-row
        v-for="item in paginList"
        :key="item.id"
        :columns="tableHeadModify"
        :item="item"
        :shared="type === 'server' ? isShared(item.network_id) : shared"
        :type="type"
        @click-more="$emit('click-more', item)"
      />
    </base-table>
    <NewPagination
      :step="pagin.step"
      :start="pagin.start || 0"
      :count="dataset.length"
      type="small"
      @new-pagination="changePagination"
      @set-step="setStep"
    />
  </div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable';
import TariffsTablePortRow from '@/layouts/Stack/pages/Main/components/TariffsTablePortRow';
import NewPagination from '@/components/Pagination/NewPagination';
import pagination from '@/mixins/pagination';

export default {
  name: 'TariffsTablePort',
  components: {
    BaseTable,
    TariffsTablePortRow,
    NewPagination,
  },
  mixins: [pagination],
  props: {
    type: {
      type: String,
      default: 'port',
    },
    dataset: {
      type: Array,
      default: () => [],
    },
    shared: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableHead: [
        {
          key: 'name',
          label: this.$t('tableHead.name'),
          style: {
            width: '130px',
            maxWidth: '130px',
          },
          sort: {
            prop: 'name',
            order: 'asc',
          },
        },
        {
          key: 'attach',
          label: this.$t('tableHead.attach'),
          style: {
            width: '44px',
            maxWidth: '130px',
          },
        },
        {
          key: 'address',
          label: this.$t('tableHead.address'),
          style: {
            width: '100px',
            maxWidth: '130px',
          },
          sort: {
            prop: 'fixed_ips',
            order: 'asc',
            item: 'ip_address',
          },
        },
        {
          key: 'macaddress',
          label: this.$t('tableHead.macaddress'),
          style: {
            width: '100px',
            maxWidth: '130px',
          },
          sort: {
            prop: 'mac_address',
            order: 'asc',
          },
        },
        {
          key: 'floatingip',
          label: this.$t('tableHead.floatingip'),
          style: {
            width: '44px',
          },
        },
        {
          key: 'dhcp',
          label: this.$t('tableHead.dhcp'),
          style: {
            width: '44px',
          },
        },
        {
          key: 'firewall',
          label: this.$t('tableHead.firewall'),
          style: {
            width: '44px',
          },
        },

        // {
        //   key: 'status',
        //   label: this.$t('tableHead.status'),
        //   style: {
        //     width: '54px',
        //   },
        // },
        {
          label: this.$t('tableHead.menu'),
          key: 'more',
          style: {
            width: '44px',
          },
        },
      ],
      /*stepOptions: [10, 25, 50, 100, 'all'],
      pagin: {
        start: 0,
        step: 10,
      },*/
    };
  },
  computed: {
    network() {
      return this.$store.state.moduleStack.networks.filter(x => x.shared === false);
    },
    tableHeadModify() {
      return this.shared ? this.tableHead.filter(x => x.key !== 'floatingip') : this.tableHead;
    },
    sort() {
      return this.$store.state.moduleStack.sort.port;
    },

    paginList() {
      // if (this.pagin.step === 'all') return this.dataset;
      const { start, step } = this.pagin;
      const end = start + step;
      return this.dataset.slice(start, end);
    },
  },
  watch: {
    sort: {
      handler(val /*old*/) {
        this.updateSort(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.updateSort(this.sort);
  },
  methods: {
    isShared(id) {
      return this.$store.state.moduleStack.networks.find(x => x.id === id).shared;
    },
    /*setStep(value) {
      this.pagin.step = value;
    },
    changePagination(value) {
      this.pagin.step = value.rows;
      this.pagin.start = value.first;
    },*/
    updateSort(sort) {
      const col = this.tableHead.find(i => i.sort && i.sort.prop === sort.prop);
      col.sort.order = sort.order;
    },
    onSortChange(payload) {
      let { prop, order } = payload;
      if (this.sort.prop === prop) order = order === 'asc' ? 'desc' : 'asc';
      this.$store.commit('moduleStack/SET_PORT_SORT', { prop, order });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "tableHead": {
      "name": "Имя",
      "address": "Адрес",
      "macaddress": "MAC-адрес",
      "floatingip": "Плавающий IP",
      "gateway": "Шлюз",
      "dhcp": "Состояние",
      "firewall": "Файрвол",
      "config": "Конфигурация",
      "status": "Статус",
      "subnet": "Подсеть",
      "attach": "Подключен к",
      "menu": "Меню"
    },
    "activeUnder": "До {date}",
    "selectAll": "Выбрать все",
    "selected": "Выбрано 0 серверов | Выбран 1 сервер | Выбрано {n} сервера | Выбрано {n} серверов",
    "prolong": "Продлить"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table {
  width: 100%;

  &__tools {
    flexy(space-between, center);
    margin-bottom: 1.5rem;

    &-end {
      flexy(flex-end, center);
    }

    &-note {
      margin-right: 2.5rem;
    }
  }

  &__pagination {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
